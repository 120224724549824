var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("e-sidebar", {
        attrs: {
          title: _vm.isEdit ? _vm.$t("Editar Caixa") : _vm.$t("Novo Caixa"),
          show: _vm.showSidebar,
          fetching: _vm.fetching,
          saving: _vm.saving,
          width: "500px",
        },
        on: {
          save: _vm.savePayBox,
          hidden: function ($event) {
            _vm.showSidebar = false
          },
        },
        scopedSlots: _vm._u([
          {
            key: "content",
            fn: function () {
              return [
                _c(
                  "FormulateForm",
                  { ref: "payBoxForm", attrs: { name: "payBoxForm" } },
                  [
                    _c(
                      "b-row",
                      [
                        _c(
                          "b-col",
                          { attrs: { md: "12" } },
                          [
                            _c("e-store-combo", {
                              attrs: { id: "manage_pay_box_sidebar-store_id" },
                              model: {
                                value: _vm.localPayBox.storeId,
                                callback: function ($$v) {
                                  _vm.$set(_vm.localPayBox, "storeId", $$v)
                                },
                                expression: "localPayBox.storeId",
                              },
                            }),
                          ],
                          1
                        ),
                        _c(
                          "b-col",
                          { attrs: { md: "5" } },
                          [
                            _c("FormulateInput", {
                              staticClass: "required",
                              attrs: {
                                id: "manage_pay_box_sidebar-number",
                                type: "number",
                                label: _vm.$t("Número do caixa"),
                                validation: "required|number",
                              },
                              model: {
                                value: _vm.localPayBox.number,
                                callback: function ($$v) {
                                  _vm.$set(_vm.localPayBox, "number", $$v)
                                },
                                expression: "localPayBox.number",
                              },
                            }),
                          ],
                          1
                        ),
                        _c(
                          "b-col",
                          { attrs: { md: "12" } },
                          [
                            _c("FormulateInput", {
                              attrs: {
                                id: "manage_pay_box_sidebar-description",
                                type: "textarea",
                                label: _vm.$t("Descrição"),
                              },
                              model: {
                                value: _vm.localPayBox.description,
                                callback: function ($$v) {
                                  _vm.$set(_vm.localPayBox, "description", $$v)
                                },
                                expression: "localPayBox.description",
                              },
                            }),
                          ],
                          1
                        ),
                        _c(
                          "b-col",
                          { attrs: { md: "12" } },
                          [
                            _c("FormulateInput", {
                              attrs: {
                                id: "manage_pay_box_sidebar-event-id",
                                type: "vue-select",
                                label: _vm.$t("Evento"),
                                options: _vm.eventOptions,
                              },
                              model: {
                                value: _vm.localPayBox.eventId,
                                callback: function ($$v) {
                                  _vm.$set(_vm.localPayBox, "eventId", $$v)
                                },
                                expression: "localPayBox.eventId",
                              },
                            }),
                          ],
                          1
                        ),
                        _c(
                          "b-col",
                          { attrs: { md: "12" } },
                          [
                            _c("FormulateInput", {
                              attrs: {
                                type: "switch",
                                label: _vm.$t("ATM"),
                                instruction: _vm.$t(
                                  "Indica se o caixa é de auto atendimento"
                                ),
                              },
                              model: {
                                value: _vm.localPayBox.isAtm,
                                callback: function ($$v) {
                                  _vm.$set(_vm.localPayBox, "isAtm", $$v)
                                },
                                expression: "localPayBox.isAtm",
                              },
                            }),
                          ],
                          1
                        ),
                        _c(
                          "b-col",
                          { attrs: { md: "6" } },
                          [
                            _c("FormulateInput", {
                              attrs: {
                                id: "manage_pay_box_sidebar-anydesk_id",
                                type: "number",
                                label: _vm.$t("Id AnyDesk"),
                              },
                              model: {
                                value: _vm.localPayBox.anyDeskId,
                                callback: function ($$v) {
                                  _vm.$set(_vm.localPayBox, "anyDeskId", $$v)
                                },
                                expression: "localPayBox.anyDeskId",
                              },
                            }),
                          ],
                          1
                        ),
                        _c(
                          "b-col",
                          { attrs: { md: "6" } },
                          [
                            _c("FormulateInput", {
                              attrs: {
                                id: "manage_pay_box_sidebar-anydesk_password",
                                type: "text",
                                label: _vm.$t("Senha AnyDesk"),
                              },
                              model: {
                                value: _vm.localPayBox.anyDeskPassword,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.localPayBox,
                                    "anyDeskPassword",
                                    $$v
                                  )
                                },
                                expression: "localPayBox.anyDeskPassword",
                              },
                            }),
                          ],
                          1
                        ),
                        _c(
                          "b-col",
                          { attrs: { md: "6" } },
                          [
                            _c("FormulateInput", {
                              attrs: {
                                id: "manage_pay_box_sidebar-teamviewer_id",
                                type: "number",
                                label: _vm.$t("Id TeamViewer"),
                              },
                              model: {
                                value: _vm.localPayBox.teamViewerId,
                                callback: function ($$v) {
                                  _vm.$set(_vm.localPayBox, "teamViewerId", $$v)
                                },
                                expression: "localPayBox.teamViewerId",
                              },
                            }),
                          ],
                          1
                        ),
                        _c(
                          "b-col",
                          { attrs: { md: "6" } },
                          [
                            _c("FormulateInput", {
                              attrs: {
                                id: "manage_pay_box_sidebar-teamviewer_password",
                                type: "text",
                                label: _vm.$t("Senha TeamViewer"),
                              },
                              model: {
                                value: _vm.localPayBox.teamViewerPassword,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.localPayBox,
                                    "teamViewerPassword",
                                    $$v
                                  )
                                },
                                expression: "localPayBox.teamViewerPassword",
                              },
                            }),
                          ],
                          1
                        ),
                        _vm.isEdit
                          ? _c(
                              "b-col",
                              { attrs: { md: "12" } },
                              [
                                _c("FormulateInput", {
                                  staticClass: "required",
                                  attrs: {
                                    id: "pay_box_sat_tab-preferred_invoice_type",
                                    type: "vue-select",
                                    label: _vm.$t("Documento fiscal padrão"),
                                    placeholder: _vm.$t(
                                      "Selecione o documento fiscal padrão"
                                    ),
                                    options:
                                      _vm.localPayBox.availableInvoiceTypes,
                                    validation: "required",
                                  },
                                  model: {
                                    value: _vm.localPayBox.preferredInvoiceType,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.localPayBox,
                                        "preferredInvoiceType",
                                        $$v
                                      )
                                    },
                                    expression:
                                      "localPayBox.preferredInvoiceType",
                                  },
                                }),
                              ],
                              1
                            )
                          : _vm._e(),
                        _vm.isEdit
                          ? _c(
                              "b-col",
                              { attrs: { md: "12" } },
                              [
                                _c("FormulateInput", {
                                  staticClass: "required",
                                  attrs: {
                                    id: "manage_pay_box_sidebar-nfce-last_invoice_number",
                                    type: "number",
                                    label: _vm.$t(
                                      "Código da última NFC-e emitida"
                                    ),
                                    validation: "required",
                                  },
                                  model: {
                                    value:
                                      _vm.localPayBox.nfce.lastInvoiceNumber,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.localPayBox.nfce,
                                        "lastInvoiceNumber",
                                        $$v
                                      )
                                    },
                                    expression:
                                      "localPayBox.nfce.lastInvoiceNumber",
                                  },
                                }),
                              ],
                              1
                            )
                          : _vm._e(),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _vm.localPayBox.finishedSetup
                  ? _c(
                      "b-card-actions",
                      {
                        attrs: {
                          "no-actions": "",
                          title: _vm.$t("Outras informações"),
                        },
                      },
                      [
                        _c("b-col", { attrs: { md: "12" } }, [
                          _c("strong", [
                            _vm._v(_vm._s(_vm.$t("Endereço MAC")) + ":"),
                          ]),
                          _c("br"),
                          _c("span", [
                            _vm._v(_vm._s(_vm.localPayBox.macAddress)),
                          ]),
                        ]),
                        _c("b-col", { attrs: { md: "12" } }, [
                          _c("strong", [
                            _vm._v(
                              _vm._s(_vm.$t("Configuração Completa")) + ":"
                            ),
                          ]),
                          _c("pre", [_vm._v(_vm._s(_vm.localPayBox))]),
                        ]),
                      ],
                      1
                    )
                  : _vm._e(),
              ]
            },
            proxy: true,
          },
        ]),
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }